<template>
    <div class="MFASectionView">
        <div class="MFASectionView__authDetails" v-show="isEnabledOTP === true">
            <div class="MFASectionView__authDetails--icon">
                <i class="mdi mdi-check-circle" />
            </div>
            <h3>
                {{ $l("platon.two-step-authentication-enabled", "2 босқичли аутентификация фаоллаштирилган") }}
            </h3>
        </div>
        <div class="MFASectionView__authPart">
            <div class="MFASectionView__authPart--logo">
                <img alt="Auth (2MFA) Application Provider logo" src="../assets/2MFA.png" />
            </div>
            <div class="MFASectionView__authPart__menu mfa-header">
                <h3 class="mfa-header__title">{{ $l("platon.app-authenticator", "Илова аутентификацияси") }}</h3>
                <p class="mfa-header__description">
                    {{
                        $l(
                            "platon.otp-instructions",
                            `Аутентификация иловаси ёрдамида сиз телефонингиз интернетга уланмаган бўлса ҳам тасдиқлаш кодларини исталган вақт олишингиз мумкин. Илова Android, ИОС ва Браусерлар учун ҳам мажуд`
                        )
                    }}
                </p>
                <div class="mfa-header__applications">
                    <div
                        class="mfa-header__applications--appStore"
                        @click="
                            openApplicationInBlank('https://apps.apple.com/us/app/google-authenticator/id388497605')
                        "
                    >
                        <i class="mdi mdi-apple" />
                        <div class="application-inner-text">
                            {{ $l("platon.ios-download", "Download on the") }}
                            <span class="app-name">AppStore</span>
                        </div>
                    </div>
                    <div
                        class="mfa-header__applications--playMarket"
                        @click="
                            openApplicationInBlank(
                                'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
                            )
                        "
                    >
                        <i class="mdi mdi-google-play" />
                        <div class="application-inner-text">
                            {{ $l("platon.android-download", "GET IT ON") }}
                            <span class="app-name">Google Play</span>
                        </div>
                    </div>
                    <div
                        class="mfa-header__applications--chromeExtension"
                        @click="
                            openApplicationInBlank(
                                'https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai'
                            )
                        "
                    >
                        <i class="mdi mdi-puzzle" />
                        <div class="application-inner-text">
                            <span class="app-name">Chrome Extension</span>
                        </div>
                    </div>
                </div>
                <div class="mfa-header__options">
                    <button
                        :class="`mfa-header__options--add ${isEnabledOTP ? 'disabled' : ''}`"
                        @click="openExternalModalWindow"
                    >
                        {{
                            isEnabledOTP
                                ? $l("platon.set-otp-security", "Аутентификасия учун дастурни қайта фаоллаштириш")
                                : $l("platon.modify-otp-security", "Аутентификация дастурини созлаш ва фаоллаштириш")
                        }}
                    </button>
                    <div
                        class="mfa-header__options--toggleMFAOption"
                        v-show="isEnabledOTP"
                        @click="openUnAuthorizationModal"
                    >
                        <i class="mdi mdi-trash-can-outline" />
                        <h3 class="toggle-switch-content">
                            {{ $l("platon.disable-otp-security", "Учириш") }}
                        </h3>
                        <div class="toggle-switch-backdrop" v-show="isMFAOptionLoading">
                            <div role="status" class="spinner-border spinner-border-sm text-primary ml-2" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <xModal
            v-show="isModalOpen"
            :isOpen="isModalOpen"
            :styles="{ width: 600, height: 510 }"
            :header="$l('platon.otp-in-process', 'Аутентификация дастури фаоллаштирилмоқда')"
            @on-close="closeExternalModalWindow"
        >
            <div class="SwipeableView">
                <div class="SwipeableView__panel">
                    <div class="SwipeableView__panel--first" id="SwipeableViewPrev">
                        <QRCAuthentication :state="authenticationProps" />
                    </div>
                    <div class="SwipeableView__panel--second" id="SwipeableViewNext">
                        <AuthorizationWithMFA
                            :state="authorizationProps"
                            @authorization="getAuthorizationQR"
                            @authorizationSubmit="authorizationSubmit"
                        />
                    </div>
                </div>
                <div class="SwipeableView__options">
                    <div class="SwipeableView__options--right">
                        <button @click="slideToPrev" v-show="panelIndex === 2">
                            {{ $l("platon.action-button-back", "Ортга") }}
                        </button>
                    </div>
                    <div class="SwipeableView__options--left">
                        <button @click="closeExternalModalWindow">
                            {{ $l("platon.action-button-cancel", "Бекор қилиш") }}
                        </button>
                        <button
                            @click="slideToNext"
                            v-if="panelIndex === 1"
                            :disabled="!authenticationProps.isDone"
                            :class="!authenticationProps.isDone && 'disabled'"
                        >
                            {{ $l("platon.action-button-next", "Кейинигиси") }}
                        </button>
                        <ActionButton
                            :isLoading="authorizationProps.isAuthenticating"
                            v-if="panelIndex === 2"
                            :className="`primary ${authorizationProps.isError && 'error-disabled'} ${
                                authorizationProps.verified && 'succeeded'
                            }`"
                            @onclick="confirmMFAAuthorization"
                        >
                            {{
                                authenticationProps.verified
                                    ? $l("platon.alert-otp-is-enabled", "ОТП ёқилган")
                                    : $l("platon.action-button-verify", "Тасдиқлаш")
                            }}
                        </ActionButton>
                    </div>
                </div>
            </div>
        </xModal>
        <xModal
            v-show="isUnAuthModalOpen"
            :isOpen="isUnAuthModalOpen"
            :styles="{ width: 600, height: 510 }"
            :header="$l('platon.otp-disabling-title', 'Аутентификация дастури ўчирилмоқда')"
            @on-close="closeExternalUnAuthModalWindow"
        >
            <UnAuthorizationWithMFA
                :state="unAuthorizationProps"
                @disabled="confirmMFAToggleOption"
                @getUnAuthDigits="getUnAuthDigits"
                @submitUnAuth="confirmMFAToggleOption"
            />
        </xModal>
    </div>
</template>

<script>
import xModal from "../components/xModal"
import Modal from "../../../components/extended/Modal"
import QRCAuthentication from "./MultiAuthentication/QRCAuthentication"
import AuthorizationWithMFA from "./MultiAuthentication/AuthorizationWithMFA"
import UnAuthorizationWithMFA from "./MultiAuthentication/UnAuthorizationWithMFA.vue"
import ActionButton from "../components/actionButton"
import ToastMixin from "@Platon/mixins/ToastMixin"
import { errorToast, successToast } from "@Platon/core/functions/toast"
import { API_BASE_URL } from "@Platon/const"
import { asyncHandler } from "../utils"

export default {
    name: "MFASection",
    components: { xModal, QRCAuthentication, AuthorizationWithMFA, ActionButton, UnAuthorizationWithMFA },
    data() {
        return {
            isModalOpen: false,
            defaultTransformWidth: 600,
            prevView: null,
            nextView: null,
            panelIndex: 1,

            otpCode: null,

            authenticationProps: {
                QRAuth: null,
                isDone: false,
                isError: false
            },

            authorizationProps: {
                verified: false,
                isAuthenticating: false,
                isError: false
            },

            isEnabledOTP: this.$user.hasOtpSecret,
            isMFAOptionLoading: false,

            isUnAuthModalOpen: false,
            isOTPToggle: this.$user.hasOtpSecret,

            unAuthorizationProps: {
                verified: false,
                digits: null,
                isAuthenticating: false,
                isError: false
            }
        }
    },
    methods: {
        getUnAuthDigits(digits) {
            this.unAuthorizationProps.digits = digits
        },

        closeExternalModalWindow() {
            this.isModalOpen = false
            this.slideToPrev()
            this.authorizationProps = {
                verified: false,
                isAuthenticating: false,
                isError: false
            }
        },
        closeExternalUnAuthModalWindow() {
            this.isUnAuthModalOpen = false
            this.unAuthorizationProps = {
                verified: false,
                isAuthenticating: false,
                digits: null,
                isError: false
            }
        },
        getAuthorizationQR(otpCode) {
            this.otpCode = otpCode
            if (this.authorizationProps.isError) this.authorizationProps.isError = false
        },
        async authorizationSubmit() {
            await this.confirmMFAAuthorization()
        },
        openApplicationInBlank(applicationURL) {
            window.open(applicationURL)
        },
        async openExternalModalWindow() {
            this.isModalOpen = true
            await this.getQRAuthorizationCode()
        },
        slideToPrev() {
            this.panelIndex = 1
            this.prevView.style.transform = `translateX(0px)`
            this.nextView.style.transform = `translateX(${this.defaultTransformWidth}px)`
        },
        slideToNext() {
            this.panelIndex = 2
            this.prevView.style.transform = `translateX(-${this.defaultTransformWidth}px)`
            this.nextView.style.transform = `translateX(-${this.defaultTransformWidth}px)`
        },
        async getQRAuthorizationCode() {
            this.authenticationProps.isDone = false
            await asyncHandler(API_BASE_URL + "auth/enable-otp", {
                onSuccess: (response) => {
                    this.authenticationProps.QRAuth = response.data
                    this.authenticationProps.isDone = true
                }
            })
        },
        openUnAuthorizationModal() {
            this.isUnAuthModalOpen = true
            this.isOTPToggle = true
        },
        async confirmMFAToggleOption() {
            if (String(this.unAuthorizationProps.digits).length < 6) {
                this.unAuthorizationProps.isError = true
                this.unAuthorizationProps.isAuthenticating = false
                return false
            }
            this.unAuthorizationProps.isError = false
            this.unAuthorizationProps.isAuthenticating = true
            this.isOTPToggle = true

            await asyncHandler(`${API_BASE_URL}auth/disable-otp?otp=${this.unAuthorizationProps.digits}`, {
                type: "post",
                onSuccess: (response) => {
                    if (response.data.statusCode === 200) {
                        this.unAuthorizationProps = {
                            ...this.unAuthorizationProps,
                            isError: false,
                            isAuthenticating: false,
                            verified: true
                        }
                        this.isEnabledOTP = false
                        this.isOTPToggle = false
                        setTimeout(() => this.closeExternalUnAuthModalWindow(), 500)
                    } else
                        this.unAuthorizationProps = {
                            ...this.unAuthorizationProps,
                            isError: true,
                            isAuthenticating: false
                        }
                },
                onError: () => {
                    this.unAuthorizationProps = {
                        ...this.unAuthorizationProps,
                        isError: true,
                        isAuthenticating: false
                    }
                }
            })
        },
        async confirmMFAAuthorization() {
            this.isAuthenticating = true
            await asyncHandler(`${API_BASE_URL}auth/verify-otp?otp=${this.otpCode}`, {
                type: "post",
                onSuccess: (response) => {
                    if (response.data.verified) {
                        this.authorizationProps = {
                            ...this.authorizationProps,
                            verified: true,
                            isAuthenticating: false
                        }
                        this.isEnabledOTP = true
                        // this.greenToast(this.$l("platon.form_saved", "Маълумотлар сақланди"));
                        setTimeout(() => this.closeExternalModalWindow(), 800)
                    } else
                        this.authorizationProps = {
                            ...this.authorizationProps,
                            verified: false,
                            isAuthenticating: false,
                            isError: true
                        }
                }
            })
        }
    },
    mounted() {
        this.prevView = document.querySelector("#SwipeableViewPrev")
        this.nextView = document.querySelector("#SwipeableViewNext")
    }
}
</script>

<style lang="scss" scoped>
@import "../components/utils/mixins.scss";

// TODO: Need to move it inside of the <script> tag

.SwipeableView {
    overflow: hidden;

    &__panel {
        @include display-flex;

        &--first {
            width: 100%;
            transform: translateX(0);
            transition: all 0.15s cubic-bezier(0.08, 0.84, 0.44, 1.02);
        }

        &--second {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            transition: all 0.15s cubic-bezier(0.08, 0.84, 0.44, 1.02);
            transform: translateX($df-panel-width);
        }
    }

    &__options {
        padding: 0 10px 10px;
        @include display-flex;
        justify-content: space-between;
        &--right,
        &--left {
            @include display-flex;

            & button {
                padding: 10px;
                color: $df-color;
                cursor: pointer;
                // border: 1px solid $df-border-color;
                border: 1px solid rgba($color: $df-color, $alpha: 0.4);
                border-radius: $df-border-radius;
                background-color: #fff;
                transition: all 0.15s ease;
                box-shadow: 1px 1px 5px 0px rgb(151 171 180 / 10%);

                &:hover {
                    background-color: rgba($color: $df-border-color, $alpha: 0.8);
                }
                &.primary {
                    color: #fff;
                    background-color: $df-color-primary;
                    border-color: $df-color-primary;
                    &:hover {
                        background-color: rgba($color: $df-color-primary, $alpha: 0.8);
                    }
                }

                &.disabled {
                    box-shadow: none;
                    cursor: no-drop;
                    color: rgba($color: $df-color, $alpha: 0.5);
                    background-color: rgba($color: $df-dry-color, $alpha: 0.1);
                    &:hover {
                        background-color: rgba($color: $df-dry-color, $alpha: 0.5);
                    }
                }

                &.error-disabled {
                    border-color: $df-secondary;
                    background-color: rgba($color: $df-secondary, $alpha: 0.8);
                    &:hover {
                        background-color: rgba($color: $df-secondary, $alpha: 0.5);
                    }
                }

                &.succeeded {
                    border-color: $df-success;
                    background-color: rgba($color: $df-success, $alpha: 0.8);
                    &:hover {
                        background-color: rgba($color: $df-success, $alpha: 0.5);
                    }
                }

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
    }
}

.MFASectionView {
    padding: 10px 15px;
    background-color: #fff;

    &__authDetails {
        cursor: pointer;
        height: 70px;
        padding: 0 12px 0 10px;
        margin-bottom: 35px;
        display: flex;
        width: fit-content;
        align-items: center;
        background-color: #fff;
        justify-content: flex-start;
        border: 1.5px solid $df-border-color;
        border-radius: $df-border-radius;

        transition: all 0.1s ease-out;
        &:hover {
            background-color: rgba($color: $df-color-gray, $alpha: 0.5);
        }

        &--icon {
            width: 40px;
            height: fit-content;
            margin-right: 2px;
            @include display-flex-center;

            & i {
                font-size: 25px;
                color: $df-success;
            }
        }

        & h3 {
            margin: 0;
            font-size: $df-size-small;
            font-weight: $df-w-middle;
        }
    }

    &__authPart {
        display: flex;
        align-items: stretch;

        &--logo {
            width: 90px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }

        &__menu {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
        }

        & .mfa-header {
            &__title {
                width: 100%;
                color: $df-color;
                font-weight: $df-w-bold;
                font-size: $df-size-small;
            }

            &__description {
                width: 55%;
                color: $df-color;
                display: inline-block;
                font-weight: $df-w-middle;
                font-size: calc($df-middle - 2px);
            }

            &__applications {
                @include display-flex;

                &--playMarket,
                &--chromeExtension {
                    margin-left: 1rem;
                }

                &--chromeExtension i {
                    margin-right: 5px;
                }

                &--appStore,
                &--playMarket,
                &--chromeExtension {
                    height: 42px;
                    width: 117px;
                    padding: 10px;
                    @include display-flex;
                    justify-content: space-between;
                    background-color: $df-color-gray;
                    color: $df-color-primary;
                    border: 1.5px solid rgba($color: $df-icon-color, $alpha: 0.5);
                    border-radius: $df-border-radius;
                    background-color: $df-color-gray;

                    & i {
                        font-size: 28px;
                        color: $df-color-primary;
                    }

                    & .application-inner-text {
                        font-size: 8px;
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;

                        & .app-name {
                            font-size: calc($df-size-small - 4px);
                            font-weight: $df-w-middle;
                        }
                    }

                    cursor: pointer;
                    transition: all 0.1s ease;
                    &:hover {
                        & * {
                            color: #fff;
                        }
                        border-color: $df-color-primary;
                        background-color: rgba($color: $df-color-primary, $alpha: 0.9);
                    }
                }
            }

            &__options {
                width: 100%;
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
                @include display-flex;

                &--add {
                    height: 44px;
                    color: #fff;
                    border: none;
                    padding: 10px 16px;
                    font-size: $df-middle;
                    font-weight: $df-w-middle;
                    border-radius: $df-border-radius;
                    background-color: $df-color-primary;

                    transition: all 0.15s ease-out;
                    &:hover,
                    &.disabled:hover {
                        background-color: rgba($color: $df-color-primary, $alpha: 0.8);
                    }

                    &.disabled {
                        background-color: rgba($color: $df-color-primary, $alpha: 0.7);
                    }
                }

                &--toggleMFAOption {
                    height: 43px;
                    margin-left: 1.5rem;
                    padding: 5px 10px;
                    cursor: pointer;
                    @include display-flex;

                    padding: 10px 16px;
                    border-radius: $df-border-radius;
                    background-color: $df-secondary;

                    & .toggle-switch {
                        padding-bottom: 1rem;
                    }

                    & i {
                        color: #fff;
                        font-size: 18px;
                        display: inline-block;
                        margin-right: 0.4rem;
                    }

                    & .toggle-switch-content {
                        color: #fff;
                        font-weight: $df-w-lighter;
                        font-size: $df-size-small;

                        margin: 0;
                        padding: 0;
                    }

                    position: relative;
                    & .toggle-switch-backdrop {
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 2;
                        width: 100%;
                        height: 100%;
                        @include display-flex-center;
                        background-color: rgba($color: $df-border-color, $alpha: 0.6);
                    }

                    &:hover {
                        border-color: $df-secondary;
                        background-color: rgba($color: $df-secondary, $alpha: 0.8);
                    }
                }
            }
        }
    }
}
</style>
