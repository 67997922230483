export default {
	props: {
		route: {}
	},

	data() {
		let route = this.route || this.$route

		return {
			localRoute: route
		}
	},

	methods: {
		q(queryName) {
			return this.localRoute.query[queryName]
		}
	}
}
