import { wrapFnWithContext } from "@Platon/core/condition"
import { ifNull } from "@Platon/core/helpers"

export default {
	data() {
		return {
			__fnMap: new Map()
		}
	},

	methods: {
		generateFooterDataHolder(data, footerRow) {
			let $this = this
			let proxy

			const handler = {
				get: function (target, name) {
					if (name === "_field" || name === "_depth") {
						return target[name]
					}

					if (name === "_count") {
						return data.map((x) => x[target._field]).length
					}

					if (name === "_vcount") {
						return data.map((x) => x[target._field]).filter((x) => x !== null).length
					}

					if (name === "_sum") {
						return data.map((x) => Number(x[target._field])).reduce((agr, c) => agr + ifNull(c, 0), 0)
					}

					if (name === "_avg") {
						return (
							data.map((x) => Number(x[target._field])).reduce((agr, c) => agr + ifNull(c, 0), 0) /
							data.length
						)
					}

					// if result found in cache return it, otherwise calculate
					return name in target ? target[name] : $this.callFooterFunction(footerRow[name], name, proxy)
				},

				set: function (obj, prop, value) {
					obj[prop] = value

					return true
				},

				_data: data
			}

			proxy = new Proxy(
				{
					_field: "__none__",
					_depth: 0,
					_f: this.$f.bind(this)
				},
				handler
			)

			return proxy
		},

		/**
		 * @param {TableColumn[]} cols
		 * @param {object[]} data
		 * @param {string} rowId
		 *
		 * @return {object[]}
		 */
		generateFooterFor(cols, data, rowId) {
			/**
			 * @_sum|@_avg|@_count, @ larni this. ga o'zgartirib, | bo'yicha bo'lib massiv hosil qilamiz
			 * @type array
			 */
			const footers = cols.map((c) => (c.sumType || c.footer || "").replace(/@/g, "this.").split("|"))

			// footerning balandligini hisoblaymiz
			const depth = footers.reduce((max, i) => Math.max(i.length, max), 0)

			const rows = []

			for (let i = 0; i < depth; i++) {
				// bo'sh footeri render qilmaymiz
				if (footers.every((x) => Array.isArray(x) && !x[i])) {
					continue
				}

				// bizga kerakli ko'rinishga keltirib olamiz, {f1: fn, f2: fn}
				const row = footers
					.map((x) => x[i])
					.reduce((row, val, i) => {
						row[cols[i].dataField] = val

						return row
					}, {})

				// footer row uchun data holder hosil qilamiz, row uchun cache vazifasini ham bajaradi
				const footerData = this.generateFooterDataHolder(data, row)

				// footer ma'lumotlarini hosil qilamiz
				rows.push(
					cols.reduce(
						(footer, col) => {
							let field = col.dataField

							try {
								// agar fn mavjud bo'lsa hisoblaymiz, ask holda bo'sh string
								if (row[field] && row[field].length > 0)
									footer[field] = this.callFooterFunction(row[field], field, footerData)
								else {
									footer[field] = ""
								}
							} catch (e) {
								// error bo'lsa fn o'zini footerga yozamiz
								footer[field] = row[field]

								console.warn(row[field], e.message)
							}

							return footer
						},
						{
							__rowId: `${rowId}_${i}`,
							__rowType: "footer"
						}
					)
				)
			}

			return rows
		},

		/**
		 * @desc Footerdagi formulalar shu yerda saqlanadi, har doim qayta yangi funksiya hosil qilmaslik uchun fn mapdan
		 * foydalanamiz, faqat yangi funksiyalar hosil qilinadi
		 *
		 * @param {string} fn
		 * @return {Function}
		 */
		footerCalculator(fn) {
			let formula

			if (this.$data.__fnMap.has(fn)) {
				formula = this.$data.__fnMap.get(fn)
			} else {
				formula = wrapFnWithContext(fn)

				this.$data.__fnMap.set(fn, formula)
			}

			return formula
		},

		/**
		 * @param {string} fn
		 * @param {string} field
		 * @param {object} footerData
		 *
		 * @desc Footer uchun qiymatni hisoblab beradi, field - qaysi maydon uchun hisoblanayotgani, depth - qidiruvda
		 * nechi marta ichma ich, chaqirilganligi, agar depth katta bo'lsa, demak recursiv formula yozilgan serverda
		 *
		 * @return {any}
		 */
		callFooterFunction(fn, field, footerData) {
			// eski qiymatni yozib qoyamiz pastga tushib chiqqanda qayta o'zlashtirish uchun
			let old = footerData._field

			// yangi fieldni o'zlashtiramiz, aynan shu field uchun qiymat hisoblanadi
			footerData._field = field
			// +1 pastga tushamiz
			footerData._depth += 1

			// agar xatolik bersa 0 qiymat turadi
			let value = 0

			// tushishlar soni 10 martadan oshib ketsa recursive call bo'lgani bildiradi, 0 qaytaramiz (hisoblashni imkoni yoq)
			if (footerData._depth > 10) {
				console.error(`Recursive call above 10! Checkout formula FN[ ${fn} ], FIELD = ${field}`)
			} else {
				value = this.footerCalculator(fn).call(footerData)
			}

			// qiymatni footerni ma'lumotlari ichiga yozib qoyamiz, keyinchalik kerak bo'lganda qayta hisoblamaslik uchun
			footerData[field] = value
			footerData._field = old
			// tepaga chiqamiz
			footerData._depth -= 1

			return value
		}
	}
}
