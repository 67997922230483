<template>
    <b-col :sm="item.width || 12" :element-id="item.id" class="tab-layout mb-3">
        <PlatonLink
            v-if="isAdmin && isMyProject(item)"
            class="small over-target ml-2"
            tabindex="-1"
            :link="`/forms/form_elements/${item.id}?_target=modal&form_name=${formMeta.name}`"
        >
            <i class="fa fa-cog"></i>
        </PlatonLink>

        <b-tabs
            content-class="mt-0"
            active-nav-item-class="font-weight-bold"
            class="border rounded-lg"
            justified
            card
            v-bind="tabBindAttrs"
            v-model="item.activeTab"
        >
            <TabControl :key="tab.id" v-for="tab in item.children" :tab="tab" />
        </b-tabs>
    </b-col>
</template>

<script>
import PermissionMixin from "@Platon/mixins/table/PermissionMixin"
import FormDataMixin from "@Platon/components/form/FormDataMixin"
import TabControl from "@Platon/components/form/controls/TabControl.vue"

/**
 * @property {PlatonFormElement} item
 */
export default {
    name: "TabContainerControl",
    components: { TabControl },

    mixins: [PermissionMixin, FormDataMixin],

    props: {
        item: {}
    },

    computed: {
        tabBindAttrs() {
            let attrs = {}

            if (this.item.tabsPosition === "left") {
                attrs["vertical"] = true
                attrs["pills"] = true
            } else if (this.item.tabsPosition === "right") {
                attrs["pills"] = true
                attrs["vertical"] = true
                attrs["end"] = true
            } else if (this.item.tabsPosition === "bottom") {
                attrs["end"] = true
            }

            return attrs
        },

        saveStateKey() {
            return this.item.saveStateKey
        }
    },

    methods: {
        tryToRestoreFrom(key) {
            const index = parseInt(localStorage.getItem(`f:tab:index:${key}`))

            if (index >= 0) {
                this.$set(this.item, "activeTab", index)
            }
        },

        saveState(key, tab) {
            localStorage.setItem(`f:tab:index:${key}`, tab)
        }
    },

    watch: {
        saveStateKey: {
            handler(key) {
                this.tryToRestoreFrom(key)
            },

            immediate: true
        },

        "item.activeTab"(tab) {
            if (this.saveStateKey) this.saveState(this.saveStateKey, tab)
        }
    }
}
</script>
