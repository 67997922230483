<template>
    <BaseControl :disable-label="true" v-bind="$attrs" style="margin-bottom: 6px">
        <btn
            :loading="isLoading"
            :disabled="disabled"
            :class="classes"
            style="width: 100%"
            @click.native="onButtonClicked"
            :icon="item.buttonIcon"
        >
            {{ text }}
        </btn>
    </BaseControl>
</template>

<script>
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import InputControlMixin from "@Platon/mixins/InputControlMixin"
import Btn from "@Platon/components/extended/Btn.vue"
import { runScopeFn } from "@Platon/core/condition"
import FormButtonMixin from "@Platon/components/form/controls/FormButtonMixin"

export default {
    name: "ButtonControl",
    components: { Btn, BaseControl },
    mixins: [InputControlMixin, FormButtonMixin],

    inject: ["form"],

    data() {
        return {
            isLoading: false
        }
    },

    computed: {
        classes() {
            return this.item.cssStyle || "btn btn-primary"
        }
    },

    methods: {
        onButtonClicked() {
            if (this.item.js) {
                runScopeFn(this.item.js, this.form, { button: this })
            }
        }
    }
}
</script>

<style></style>
