<template>
    <component v-if="isLoaded" :route="route" :page-data="page.data" :is="dynamicComponent" />
</template>

<script>
import ModalRouteMixin from "@Platon/mixins/ModalRouteMixin"
import mergeWith from "lodash.mergewith"
import { isArray } from "lodash"
import { transformJsToComponent } from "@Platon/components/pages/PageUtils"

/**
 * @typedef DynamicComponent
 *
 * @property {string} name
 * @property {?string} content
 * @property {?string} css
 * @property {?string} js
 * @property {DynamicComponent[]} components
 */

export default {
    name: "DynamicPageRenderer",

    props: {
        page: {
            required: true
        },

        route: {
            required: false
        }
    },

    provide() {
        return {
            $rootPageComponent: this
        }
    },

    inject: {
        $pageView: {
            default: undefined
        }
    },

    data() {
        return {
            componentDefinitions: {},
            isLoaded: false
        }
    },

    created() {
        this.buildComponentDefinitions()

        this.mountComponentScripts().then(() => {
            this.mountAllStyles().then(() => {
                this.$emit("pageLoaded")

                this.isLoaded = true
            })
        })
    },

    beforeDestroy() {
        setTimeout(() => {
            this.removeAllStyles()
        }, 2500)
    },

    methods: {
        /**
         * @param {DynamicComponent} c
         *
         * @return Partial<ComponentOptions>
         */
        transformComponent(c) {
            return transformJsToComponent(c, this.componentDefinitions)
        },

        /**
         * @param {Partial<ComponentOptions>} component
         */
        addMainComponentOptions(component) {
            function arrayMerger(objValue, srcValue) {
                if (isArray(objValue)) {
                    return objValue.concat(srcValue)
                }
            }

            return mergeWith(
                component,
                {
                    mixins: [ModalRouteMixin],

                    props: {
                        pageData: {}
                    },

                    inject: {
                        $pageView: {
                            default: undefined
                        }
                    },

                    provide() {
                        return {
                            $page: this
                        }
                    }
                },
                arrayMerger
            )
        },

        buildComponentDefinitions() {
            if (!Array.isArray(this.page.components)) return

            this.page.components.forEach(
                /** DynamicComponent */ (c) => {
                    this.componentDefinitions[c.name] = this.transformComponent(c)
                }
            )

            this.componentDefinitions[this.page.name] = this.transformComponent(this.page)
        },

        async mountComponentScripts() {
            /** @type {Set<string>} */
            const scripts = Object.values(this.componentDefinitions).reduce((list, component) => {
                if (component && Array.isArray(component.scripts)) {
                    for (let script of component.scripts) {
                        list.add(script)
                    }
                }

                return list
            }, new Set())

            const loader = Array.from(scripts.values()).map((script) => {
                return new Promise((resolve, reject) => {
                    this.addScript(script, {
                        async: true,
                        onload: () => {
                            resolve()
                        }
                    })
                })
            })

            return Promise.allSettled(loader)
        },

        async mountAllStyles() {
            /** @type DynamicComponent[] */
            let components = this.page.components

            let styles = []

            if (Array.isArray(components))
                styles = components.filter((c) => c.css).map((c) => this.addCss(this, this.fixMediaUrl(c.css)))

            if (this.page.css) {
                styles.unshift(this.addCss(this, this.fixMediaUrl(this.page.css)))
            }

            await Promise.all(styles)
        },

        removeAllStyles() {
            if (Array.isArray(this.page.components))
                this.page.components.forEach(
                    /** DynamicComponent */ (c) => {
                        if (c.css) {
                            this.removeCss(this, this.fixMediaUrl(c.css))
                        }
                    }
                )

            if (this.page.css) {
                this.removeCss(this, this.fixMediaUrl(this.page.css))
            }
        },

        /**
         *
         * @param {string} css
         */
        fixMediaUrl(css) {
            return css.replaceAll(/"\/media\/store/g, '"' + this.$media(""))
        }
    },

    computed: {
        dynamicComponent() {
            return this.addMainComponentOptions(this.transformComponent(this.page))
        }
    }
}
</script>
