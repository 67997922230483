<template>
    <div>
        <ContextMenu />
        <TooltipManager />
    </div>
</template>

<script>
import ContextMenu from "@Platon/components/extended/ContextMenu.vue"
import TooltipManager from "@Platon/components/extended/TooltipManager.vue"

export default {
    name: "PlatonApp",
    components: { TooltipManager, ContextMenu }
}
</script>
