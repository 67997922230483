<template>
    <BaseControl
        class="mt-1 pt-1"
        :disable-label="true"
        v-bind="$attrs"
        hint-position="after"
        :errors="combinedErrors"
        slot-class="d-flex align-items-center"
    >
        <b-form-checkbox
            switch
            size="lg"
            class="pb-2"
            :checked="local"
            @input="updateLocal"
            :unchecked-value="false"
            :value="true"
            :disabled="isDisabledOrReadOnly"
        >
            {{ $attrs.label }} <span class="text-danger" v-if="isRequired" v-text="'*'" />
        </b-form-checkbox>
    </BaseControl>
</template>

<script>
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import PermissionMixin from "@Platon/mixins/table/PermissionMixin"
import InputControl2Mixin from "@Platon/mixins/InputControl2Mixin"
import { or } from "@Platon/core/helpers"
import ValidationMixin from "@Platon/mixins/ValidationMixin"

export default {
    name: "CheckboxControl",
    components: { BaseControl },

    props: {
        value: {},
        defaultValue: {}
    },

    data() {
        let local = or(this.value, false)

        return {
            local: this.parseBool(local)
        }
    },

    mixins: [InputControl2Mixin, ValidationMixin, PermissionMixin],

    methods: {
        updateLocal(val) {
            this.local = val
            this.$emit("input", val)
        },

        parseBool(val) {
            if (val === "true" || val === 1 || val === "1") {
                return true
            } else if (val === "false" || val == 0 || val === "0") {
                return false
            }

            return !!val
        }
    },
    watch: {
        value(val) {
            this.local = this.parseBool(val)
        }
    }
}
</script>

<style></style>
