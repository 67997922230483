function initLocale(maxDigits, locale = "ru-RU") {
	let formatters = []

	for (let digits = 0; digits <= maxDigits; digits++) {
		formatters[digits] = new Intl.NumberFormat(locale, { maximumFractionDigits: digits })
	}

	return formatters
}

let numberFormats = initLocale(4)

export function numberFormat(val, digits = 0) {
	if (digits < 0 || !val || digits > 4) {
		return val
	}

	return numberFormats[digits].format(Number(val)).replace(/\u00a0/g, " ")
}

window.numberFormat = numberFormat
