/**
 * @param {Vue.VueConstructor} Vue
 */
export default function VueRuntimeScripts(Vue) {
	const injectedFiles = new Map()

	/**
	 * @param {string} url
	 * @param {{async: ?boolean, onload: ?function}} options
	 */
	Vue.prototype.addScript = function (url, options = {}) {
		if (injectedFiles.has(url)) {
			typeof options.onload === "function" && options.onload()
			return
		}

		const plugin = document.createElement("script")

		if (typeof options.onload === "function") {
			plugin.onload = options.onload
		}

		plugin.setAttribute("src", url)

		if (typeof options.async === "boolean") plugin.async = options.async

		injectedFiles.set(url, plugin)

		document.head.appendChild(plugin)
	}

	Vue.prototype.addStyle = function (url) {
		let style = document.createElement("link")
		style.type = "text/css"
		style.rel = "stylesheet"
		style.href = url

		injectedFiles.set(url, style)

		document.head.appendChild(style)
	}

	/**
	 * @type {Map<string, Set<Vue.Component>>}
	 */
	const injectedCss = new Map()

	/**
	 * @type {Map<string, HTMLStyleElement>}
	 */
	const cssRefs = new Map()

	Vue.prototype.addCss = function (component, css) {
		return new Promise((resolve, reject) => {
			if (!injectedCss.has(css)) {
				injectedCss.set(css, new Set())
			}

			const set = injectedCss.get(css)

			set.add(component)

			// if it's first element with this css, add, otherwise it already exists
			if (set.size === 1) {
				let style = document.createElement("style")
				style.onload = () => resolve()
				style.onerror = () => reject(new Error(`Style load error for ${src}`))
				style.innerText = css

				document.head.appendChild(style)

				cssRefs.set(css, style)
			} else {
				resolve()
			}
		})
	}

	Vue.prototype.removeCss = function (component, css) {
		if (injectedCss.has(css)) {
			let set = injectedCss.get(css)

			set.delete(component)

			if (set.size === 0) {
				if (cssRefs.has(css)) {
					let cssEl = cssRefs.get(css)

					document.head.removeChild(cssEl)

					cssRefs.delete(css)
				}
			}
		}
	}
}
