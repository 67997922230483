import PluginEvents from "@Platon/core/plugins/PluginEvents"

export default class PlatonPluginCore {
	/**
	 * @param {PlatonCoreOptions} options
	 */
	constructor(options) {
		this.options = options
		this.plugins = new Set()

		this.handlers = Object.keys(PluginEvents).reduce((obj, event) => {
			obj[event] = new Set()

			return obj
		}, {})
	}

	get app() {
		return this.options.router.app
	}

	get store() {
		return this.options.store
	}

	get $root() {
		return this.options.router.app.$root
	}

	/**
	 * @param {PlatonPlugin|PlatonPluginRegisterOptions[]} plugins
	 */
	preloadPlugins(plugins) {
		for (let plugin of plugins) {
			if (plugin instanceof Function) this.add(new plugin())
			else if (plugin.plugin && plugin.plugin instanceof Function) {
				this.add(new plugin.plugin())
			}
		}
	}

	/**
	 * @param {PlatonPlugin} plugin
	 */
	add(plugin) {
		if (this.plugins.has(plugin)) {
			throw new Error("Plugin has already loaded")
		}

		plugin.load(this)
	}

	/**
	 * @param {PlatonPlugin} plugin
	 *
	 * @return {Promise<void>}
	 */
	async unload(plugin) {
		if (!this.plugins.has(plugin)) {
			throw new Error("Plugin has not loaded")
		}

		await plugin.unload(this)
	}

	registerEvent(event, handler) {
		if (!this.handlers[event]) {
			throw new Error("Event does not exists")
		}

		this.handlers[event].add(handler)
	}

	unregisterEvent(event, handler) {
		if (!this.handlers[event]) {
			throw new Error("Event does not exists")
		}

		this.handlers[event].delete(handler)
	}

	/**
	 * @param {string} event
	 * @param {any} args
	 * @return {Promise<void>}
	 */
	async triggerEvent(event, ...args) {
		if (!this.handlers[event]) {
			throw new Error("Event does not exists")
		}

		let handlers = Array.from(this.handlers[event].values())

		for (let handler of handlers) {
			await handler(this, ...args)
		}
	}
}
