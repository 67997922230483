/**
 * @param {string} paths
 */
import { trimSlash } from "@Platon/core/helpers"

export default function pathJoin(...paths) {
	return paths.reduce((path, cur) => {
		if (path.endsWith("/")) {
			return path + trimSlash(cur)
		} else {
			return path + "/" + trimSlash(cur)
		}
	})
}
