<template>
    <div id="sidebar-menu" class="flex-grow-1">
        <b-skeleton-wrapper :loading="!menu.length">
            <template v-slot:loading>
                <div class="p-2 pl-3">
                    <MenuSkeleton
                        :width="Math.floor(Math.random() * 15)"
                        class="mb-2"
                        :key="i"
                        v-for="i in [1, 2, 3, 4, 5, 6, 7]"
                    />
                </div>
            </template>

            <ul class="platon-menu mm-show" id="side-menu">
                <!--Disable divider for first item of menu-->
                <MenuItem
                    :disable-divider="i === 0"
                    :item="m"
                    :menuIndex="i"
                    :key="`${m.projectId}_${m.id}`"
                    v-for="(m, i) in menu"
                />
            </ul>
        </b-skeleton-wrapper>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import MenuItem from "@Platon/components/menu/MenuItem.vue"
import { EventNames } from "@Platon/mixins/EventsMixin"
import MenuSkeleton from "@Platon/components/menu/MenuSkeleton.vue"
import NavigationMixin from "@Platon/mixins/NavigationMixin"
import { PLATON_HOME_REDIRECT } from "@Platon/const"
import { flattenBy } from "@Platon/core/helpers"

export default {
    name: "platon-menu",
    components: { MenuSkeleton, MenuItem },
    mixins: [NavigationMixin],
    computed: {
        ...mapGetters({
            menu: "platon/menu",
            /** @type User */
            user: "platon/user"
        })
    },
    methods: {
        ...mapMutations({
            setMenu: "platon/setMenu"
        }),

        onReloadNeed() {
            this.$chimera.loadMenu.fetch(true)
        },

        /**
         * @param {PlatonMenu[]} menu
         */
        handleNavigateToHomePage(menu) {
            const homePageUrl = PLATON_HOME_REDIRECT || process.env.VUE_APP_HOME_REDIRECT

            const rule = homePageUrl ? new RegExp(homePageUrl, "g") : new RegExp(`^\/$`, "g")

            if (!this.$route.path.match(rule)) {
                return
            }

            /** @type PlatonMenu[] */
            let flatMenu = flattenBy(menu, "children")
            let homePage = flatMenu.find((m) => m.isHomepage)

            if (homePage) {
                this.navigateTo(homePage.link, this.user)
            } else {
                let firstTablePage = flatMenu.find(
                    (m) => m.link && (m.link.startsWith("/tables/") || m.link.startsWith("tables/"))
                )

                if (firstTablePage) {
                    this.navigateTo(firstTablePage.link, this.user)
                }
            }
        },
        handleScroll(e) {
            console.log("scroll")
        }
    },

    mounted() {
        window.addEventListener(EventNames.RELOAD_MENU, this.onReloadNeed)
        this.onReloadNeed()
    },

    beforeDestroy() {
        window.removeEventListener(EventNames.RELOAD_MENU, this.onReloadNeed)
    },

    chimera: {
        loadMenu() {
            return {
                auto: false,
                url: "menus",
                on: {
                    /**
                     * @param {PlatonMenu[]} menu
                     */
                    success({ data: menu }) {
                        this.setMenu(menu)

                        this.handleNavigateToHomePage(menu)
                    }
                }
            }
        }
    }
}
</script>
