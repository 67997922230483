import { API_BASE_URL } from "@Platon/const"

export default {
	data() {
		return {
			keycloakLoading: false,
			keycloak: null,
			keycloakReady: false
		}
	},
	methods: {
		async keycloakValidate(code) {
			try {
				const {
					keycloak: { client_id }
				} = this.$projectInfo.oauth2 || { keycloak: {} }
				const { data } = await this.$api.post(
					"/auth/oauth2-validate",
					{
						code,
						client_id: client_id,
						redirect_uri: window.location.href,
						grant_type: "authorization_code"
					},
					{
						baseURL: API_BASE_URL,
						params: { method: "keycloak" }
					}
				)
				const token = data.access_token
				await this.oauthLogin({ token, method: "keycloak" })
			} catch (e) {
				return Promise.reject(e)
			}
		},
		loadKeycloak() {
			const {
				keycloak: { baseUrl, realm, client_id }
			} = this.$projectInfo.oauth2 || { keycloak: {} }
			const OPTIONS = {
				url: baseUrl,
				realm,
				clientId: client_id
			}
			this.keycloakLoading = true
			return new Promise((resolve, reject) => {
				this.addScript("https://cdn.jsdelivr.net/npm/keycloak-js@20.0.1/dist/keycloak.min.js", {
					async: true,
					onload: () => {
						try {
							this.keycloak = new Keycloak(OPTIONS)
							window.$keyclok = this.$keycloak
							this.keycloak.init({}).then(() => {
								this.keycloakLoading = false
								resolve()
							})
						} catch (e) {
							reject(e)
						}
					}
				})
			})
		},
		async loginWithKeycloak() {
			if (!this.keycloakLoading) window.location.href = this.keycloak.createLoginUrl()
		},
		getKeycloakCodeAndClearHash() {
			let code = null
			const hash = this.$route.hash
			if (hash) {
				const [_, codeQuery] = hash.split("code=")
				if (codeQuery) {
					code = codeQuery
					this.$router.replace({ hash: "" })
				}
			}
			return code
		},
		async initKeycloak() {
			const code = this.getKeycloakCodeAndClearHash()
			if (code) {
				try {
					await this.keycloakValidate(code)
				} catch (e) {
					this.$emit("login-error", "Keycloak орқали киришда хатолик юз берди. Қайта уриниб кўринг.")
					await this.loadKeycloak()
				}
			} else {
				await this.loadKeycloak()
			}
			this.keycloakReady = true
		}
	}
}
