export function base64ToFile(base64, filename = "file", type = "application/octet-stream") {
	// extract content type and base64 payload from original string
	// const pos = base64.indexOf(';base64,');
	// const type = base64.substring(5, pos);
	// const b64 = base64.substr(pos + 8);

	// decode base64
	const imageContent = atob(base64)

	// create an ArrayBuffer and a view (as unsigned 8-bit)
	const buffer = new ArrayBuffer(imageContent.length)
	const view = new Uint8Array(buffer)

	// fill the view, using the decoded base64
	for (let n = 0; n < imageContent.length; n++) {
		view[n] = imageContent.charCodeAt(n)
	}

	// convert ArrayBuffer to Blob
	const blob = new Blob([buffer], { type: type })

	// finally, return file
	return new File([blob], filename)
}

/**
 * @param {File} file
 */
export async function fileToBase64(file) {
	return new Promise((res, rej) => {
		const reader = new FileReader()
		reader.onload = (e) => res(e.target.result)
		reader.onerror = (e) => rej(e)
		reader.readAsDataURL(file)
	})
}

export function blobToFile(blob, filename, type) {
	return new File([blob], filename, { type })
}
