import { API_BASE_URL } from "@Platon/const"
import EimzoMixin from "./EimzoMixin"

export default {
	mixins: [EimzoMixin],
	data() {
		return {
			isEimzoLoading: false,
			isIDCardLoading: false
		}
	},
	mounted() {
		if (this.$route.query.provider === "e-imzo") {
			this.pickKey()
		}
	},
	methods: {
		pickKey() {
			let text = new Date().getTime().toString()

			this.isEimzoLoading = true

			this.$platonApp.signWithEIMZO(
				text,
				async (signResult, loadedKey) => {
					try {
						await this.completeEIMZOLogin(signResult)

						localStorage.setItem("login_eimzo_key", JSON.stringify(loadedKey))
					} catch (e) {
						let { response } = e
						if (response && response.data && response.data.message) {
							let status = response.status
							let msg = response.data.message
							this.$emit("login-error", `${status}: ${msg}`)
						} else this.$emit("login-error", e.message)
					}

					this.isEimzoLoading = false
				},
				{
					title: this.$l("platon.enter_whith_eimzo", "Электрон рақамли имзо билан кириш"),
					signText: this.$l("platon.enter", "Кириш"),
					timestampProvider: this.getChallengeForSignature,
					closed: () => {
						this.isEimzoLoading = false
					},
					hasChallenge: true
				}
			)
		},

		async pickIDCard() {
			this.isIDCardLoading = true
			let data = await this.getChallengeForSignature
			try {
				const result = await this.client.createPkcs7("idcard", data, this.$platonApp.getTimestampForSignature)
				await this.completeEIMZOLogin(result)
			} catch (err) {
				throw new Error(err)
			} finally {
				this.isIDCardLoading = false
			}
		},

		async getChallengeForSignature() {
			const timestampResult = await this.$api.get("/auth/e-imzo", {
				baseURL: API_BASE_URL
			})

			return timestampResult.data.challenge
		},

		async completeEIMZOLogin(signedPcks) {
			try {
				let loginResult = await this.$api.post("/auth/e-imzo", signedPcks, {
					baseURL: API_BASE_URL,
					headers: { "Content-Type": "text/plain" }
				})

				let { token, user } = loginResult.data

				this.$emit("login-success", {
					user,
					token,
					parcel: { response: loginResult, signedPcks, type: "login-EDS" }
				})
			} catch (e) {
				if (e.response && e.response.data && e.response.data.message) {
					let msg = e.response.data.message

					if (msg === "Individuals are not allowed to login") {
						this.$emit("login-error", "Тизимга фақат юридик шахслар кириши мумкин")
						return
					} else if (msg === "Legal entities are not allowed to login") {
						this.$emit("login-error", "Тизимга фақат жисмоний шахслар кириши мумкин")
						return
					} else if (msg === "Invalid signer certificate") {
						this.$emit("login-error", "Калит билан имзолашда хатолик")
						return
					} else if (msg === "Revoked signer certificate") {
						this.$emit(
							"login-error",
							"Электрон имзо бекор қилинган. <a href='https://doc.platon.uz/uz/platon-users' target='_blank'>Батафсил</a>"
						)
						return
					} else if (msg === "You're not allowed to login") {
						this.$emit(
							"login-error",
							"Ушбу электрон рақамли имзо орқали тизимга киришга рухсат этилмаган. <a href='https://doc.platon.uz/uz/platon-users' target='_blank'>Батафсил</a>"
						)
						return
					}
				}

				throw e
			}
		}
	}
}
