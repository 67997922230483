<template>
    <BaseControl v-bind="$attrs">
        <b-form-row
            class="plfiledrag__holder flex-grow-1"
            @dragenter="dragEnter"
            @dragover="dragOver"
            @dragleave="dragLeave"
            @drop="drop"
        >
            <UploadMultipleItem
                v-for="(f, index) in remoteFiles"
                :key="f.id"
                :remote="f"
                style="width: 200px"
                v-bind="{ 'element-id': $attrs['element-id'] }"
                @deleted="remoteFiles.splice(index, 1)"
            />

            <UploadMultipleItem
                v-for="(f, index) in selectedFiles"
                :key="f.id"
                :info="f"
                style="width: 200px"
                v-bind="{ 'element-id': $attrs['element-id'] }"
                @deleted="selectedFiles.splice(index, 1)"
                v-model="localFilesId[index]"
                @cancel="selectedFiles.splice(index, 1)"
            />

            <label for="plfiledrag__input" class="plfiledrag" v-if="canSelectFile">
                <button type="button" class="plfiledrag__btn" @click="$refs.input.click()" v-if="!isDisabledOrReadOnly">
                    <i class="fas fa-cloud-upload-alt"></i>
                    {{ $l("platon.form_file", "Файл") }}
                </button>

                <input
                    id="plfiledrag__input"
                    type="file"
                    :accept="allowedExtensions"
                    @input="onFilesSelected($event.target.files)"
                    class="plfiledrag__input custom-file-input drag__input"
                    ref="input"
                    multiple
                />
            </label>
        </b-form-row>
    </BaseControl>
</template>

<script>
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import UploadMultipleItem from "@Platon/components/form/controls/UploadMultipleItem.vue"
import InputControlMixin from "@Platon/mixins/InputControlMixin"
import UploadMultipleLimitsMixin from "@Platon/components/form/controls/UploadMultipleLimitsMixin"
import ToastMixin from "@Platon/mixins/ToastMixin"
import { base64ToFile } from "@Platon/core/helpers/FileHelpers"

export default {
    name: "UploadMultiple",
    components: { UploadMultipleItem, BaseControl },

    mixins: [InputControlMixin, UploadMultipleLimitsMixin, ToastMixin],

    data() {
        return {
            localFilesId: [],
            selectedFiles: []
        }
    },

    mounted() {
        this.emitValue()
    },

    provide() {
        return {
            item: this.item,
            uploadControl: this
        }
    },

    computed: {
        remoteFiles() {
            return this.item.data || []
        }
    },

    methods: {
        emitValue() {
            let f = this.remoteFiles.map((x) => x.id)
            f.push(...this.localFilesId)

            this.updateLocal(f)
        },

        /**
         * @param base64
         * @param filename
         * @param type
         */
        async uploadBase64File(base64, filename = "image.jpg", type = "image/jpeg") {
            return this.onFilesSelected([base64ToFile(base64, filename, type)])
        },

        dragEnter(event) {
            event.preventDefault()
        },

        dragOver(event) {
            event.preventDefault()
        },

        dragLeave(event) {
            event.preventDefault()
        },

        drop(event) {
            event.preventDefault()
            const files = event.dataTransfer.files
            if (files.length > 0) {
                this.onFilesSelected(files)
            }
        }
    },

    watch: {
        localFilesId(val) {
            this.emitValue()
        },

        remoteFiles() {
            this.emitValue()
        }
    }
}
</script>

<style lang="scss">
.plfiledrag {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;

    &__holder {
        border: 1px dashed var(--pl-primary-color);
        padding: 0.25rem;
        border-radius: 5px;
        justify-content: center;
    }

    &__input {
        all: unset;
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        top: 100%;
        opacity: 0;
    }

    &__btn {
        all: unset;
        padding: 0.2rem 0.5rem;
        border-radius: 5px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        gap: 7px;
        font-size: 14px;
    }
}
</style>
