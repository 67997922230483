import PlatonPlugin from "@Platon/core/plugins/PlatonPlugin"
import PluginEvents from "@Platon/core/plugins/PluginEvents"
import { setNewLocale } from "@Platon/core/translations"

export default class SetLocaleOfUserAfterAuth extends PlatonPlugin {
	load(core) {
		super.load(core)

		core.registerEvent(PluginEvents.AfterLogin, this.AfterLogin.bind(this))
	}

	/**
	 * @param {PlatonPluginCore} core
	 * @param {User} user
	 */
	async AfterLogin(core, user) {
		if (user.locale) {
			setNewLocale(user.locale)
		}
	}
}
