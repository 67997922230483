<template>
    <ul
        v-if="visible"
        class="dropdown dropdown-menu d-inline-block context-menu"
        :style="positionStyle"
        ref="contextMenu"
    >
        <div v-if="title" class="font-weight-bold px-3 py-1">{{ title }}</div>
        <dropdown-item
            @click="
                o.handler()
                visible = false
            "
            v-for="(o, i) in options"
            :key="i"
            :icon="o.icon"
        >
            {{ o.text }}
        </dropdown-item>
    </ul>
</template>

<script>
import DropdownItem from "./DropdownItem.vue"

export default {
    name: "ContextMenu",
    components: { DropdownItem },
    created() {
        this.$root.__context_menu = this
    },

    mounted() {
        window.addEventListener("click", this.clickOutside)
    },

    beforeDestroy() {
        window.removeEventListener("click", this.clickOutside)
    },

    data() {
        return {
            /** @type ContextMenuItem[] */
            options: [],
            position: {},
            boxDimension: { x: 0, y: 0 },
            title: "",
            visible: false
        }
    },

    methods: {
        /**
         * @param {?string} title
         * @param {ContextMenuItem[]} options
         * @param {{x:number, y:number}} position
         */
        show(title, options, position) {
            this.title = title
            this.options = options
            this.position = position
            this.visible = options.length > 0
        },

        /**
         * @param {MouseEvent} event
         */
        clickOutside(event) {
            if (this.visible) {
                if (!eventPath(event).some((x) => x === this.$refs.contextMenu)) {
                    this.visible = false
                }
            }
        },

        /**
         * @param {{x: number, y: number}} mousePosition
         * @param {{x: number, y: number}} boxDimensions
         * @param {{x: number, y: number}} screenDimension
         */
        getPositionForContextMenu(mousePosition, boxDimensions, screenDimension) {
            let width = screenDimension.x
            let halfWidth = width / 2
            let boxWidth = boxDimensions.x

            let sector = mousePosition.x < halfWidth ? 0 : -1
            let x = mousePosition.x + boxWidth * sector

            // prevent from clipping to edges of screen
            if (sector === 0) {
                x = Math.max(20, x)
            } else {
                x = Math.min(screenDimension.x - boxWidth - 20, x)
            }

            return {
                top: mousePosition.y + "px",
                left: x + "px"
            }
        }
    },

    computed: {
        positionStyle() {
            return this.getPositionForContextMenu(this.position, this.boxDimension, {
                x: window.screen.availWidth,
                y: window.screen.availHeight
            })
        }
    },

    watch: {
        visible(visible, old) {
            if (visible)
                this.$nextTick(() => {
                    let rect = this.$refs.contextMenu.getBoundingClientRect()

                    this.boxDimension = {
                        x: rect.width,
                        y: rect.height
                    }
                })
            // this.boxDimension = this.get
        }
    }
}
</script>

<style></style>
