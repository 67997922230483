<template>
    <div class="preloader" style="transition: all 0.4s ease; width: 100%">
        <div class="app-name">
            <div><span class="smooth spinner" /></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageLoadingView"
}
</script>
