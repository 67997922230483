import PlatonPlugin from "@Platon/core/plugins/PlatonPlugin"
import PluginEvents from "@Platon/core/plugins/PluginEvents"
import { themeChanger } from "@Platon/core/theme/theme-change"
import { themeModes } from "@Platon/const"

export default class SetUserThemeAfterLogin extends PlatonPlugin {
	load(core) {
		super.load(core)

		core.registerEvent(PluginEvents.SetUserTheme, this.themeInitialize.bind(this))
	}

	hasAccessToChaneTheme(core, user) {
		if (!user) return false

		const permissions = user.permissions || []
		const permitStatus = permissions.some((el) => el.startsWith("platon.admin.hidden"))

		return !permitStatus
	}

	themeInitialize(core, user) {
		// console.log("START", core, user)
		const projectTheme = this.getProjectTheme(core, user)
		const activeTheme = this.getActiveTheme(core, user)
		const storedTheme = this.getStoredTheme(core, user)
		let theme = activeTheme || (this.hasAccessToChaneTheme && storedTheme ? storedTheme : projectTheme)

		if (theme) {
			this.core.store.commit("platon/setTheme", {
				theme: theme.name,
				color: theme.color
			})

			const change = themeChanger()
			core.store.state.platon && change(core.store.state.platon.theme)
		}
	}

	getTheme(theme) {
		return themeModes.find((mode) => mode.key === theme) || themeModes[0]
	}

	getThemeColors(theme, themeColor) {
		return theme.colors.find((color) => color.key === themeColor) || theme.colors[0]
	}

	getProjectTheme() {
		if (Vue.prototype.$projectInfo && !Vue.prototype.$projectInfo.theme) return null
		const { name, color } = !Vue.prototype.$projectInfo.theme

		if (!name) return null

		return { name, color }
	}

	getStoredTheme() {
		const theme = localStorage.getItem("platon_theme")
		if (!theme) return null

		return {
			name: theme,
			color: localStorage.getItem("platon_theme_color")
		}
	}

	getActiveTheme(core, user) {
		if (!user) return null

		const permissions = user.permissions || []
		const defaultThemePermission = permissions.find((el) => el.startsWith("platon.activeTheme")) || ""
		const permissionTheme = defaultThemePermission.split(".").at(-2)
		const permissionThemeColor = defaultThemePermission.split(".").at(-1)

		if (!permissionTheme) return null

		return {
			name: permissionTheme,
			color: permissionThemeColor
		}
	}
}
