import { dangerToast, errorToast, greenToast, infoToast, successToast } from "@Platon/core/functions/toast"
import { getUser } from "@Platon/core/core"
import { has_any_perm, has_perm } from "@Platon/core/functions/permissions"

export const PermissionsScope = (extra) => {
	return {
		$user: getUser(),
		has_perm: has_perm,
		has_any_perm: has_any_perm,
		...extra
	}
}

export const Toasts = {
	dangerToast,
	successToast,
	infoToast,
	greenToast,
	errorToast
}
