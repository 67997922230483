import qs from "qs"

/**
 * @param {string} link
 * @param {Route} route
 */
export function addParamsFromRoute(link, route) {
	return addParamsFrom(link, route.query)
}

/**
 * @param {string} link
 * @param {object} params
 */
export function addParamsFrom(link, params) {
	const url = new URL(link)

	let domain = `${url.protocol}//${url.hostname}`

	if (url.protocol === "http:" && url.port !== "80") {
		domain += `:${url.port}`
	}

	if (url.protocol === "https:" && url.port !== "443") {
		domain += `:${url.port}`
	}

	let server = `${domain}${url.pathname}`
	let query = ""

	if (url.search && params) {
		// search looks like ?_level=1&a=4
		let searchParams = qs.parse(url.search.substr(1))

		Object.assign(searchParams, params)

		query = "?" + qs.stringify(searchParams, { arrayFormat: "brackets" })
	}

	return server + query
}

function tryParseJSONObject(jsonString) {
	try {
		var o = JSON.parse(jsonString)
		if (o && typeof o === "object") {
			return o
		}
	} catch (e) {}

	return jsonString
}

/**
 * @param {Object} str
 * @returns {string}
 */
export function encodeToBase64(str) {
	if (typeof str == "object") {
		return btoa(unescape(encodeURIComponent(JSON.stringify(str))))
	} else {
		return btoa(unescape(encodeURIComponent(str)))
	}
}

export function decodeToBase64(str) {
	let decodedData = decodeURIComponent(escape(atob(str)))
	return tryParseJSONObject(decodedData)
}

window.addParamsFrom = addParamsFrom
window.addParamsFromRoute = addParamsFromRoute
