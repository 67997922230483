import { VueEIMZO } from "vue-eimzo"
import HujjatUzEIMZOClient from "@Platon/core/eimzo/HujjatUzEIMZOProvider"
import { PLATON_EIMZO_CLIENT, PLATON_EIMZO_KEY } from "@Platon/const"

export default {
	computed: {
		isDirectClient() {
			return PLATON_EIMZO_CLIENT === "direct"
		}
	},
	methods: {
		getClient() {
			if (this.isDirectClient) {
				return VueEIMZO
			} else {
				return HujjatUzEIMZOClient
			}
		},
		installProjectApiKey() {
			let key = PLATON_EIMZO_KEY || process.env.VUE_APP_EIMZO_KEY

			if (key) {
				let parts = key.split(";")

				if (parts.length >= 2) {
					VueEIMZO.addApiKey(parts[0], parts[1])
				}
			}
		}
	}
}
