import { API_BASE_URL, LOGIN_ENDPOINT } from "@Platon/const"

export default {
	data() {
		return {
			isLoggingIn: false
		}
	},

	methods: {
		async tryLogin(username, password) {
			try {
				this.isLoggingIn = true
				const response = await this.$http.post(
					LOGIN_ENDPOINT,
					{
						username,
						password
					},
					{
						baseURL: API_BASE_URL
					}
				)

				this.isLoggingIn = false

				this.$emit("login-success", {
					user: response.data.user,
					token: response.data.token,
					parcel: { username, password, response, type: "login-password" }
				})
			} catch (e) {
				this.isLoggingIn = false
				const response = e.response

				if (response)
					if (response.status === 401) {
						this.$emit(
							"login-error",
							this.$l("platon.login_invalid_credentials", "Логин ёки пароль нотугри!")
						)
					} else {
						this.$emit(
							"login-error",
							response.error ? response.error.message : this.$l("platon.error", "Хатолик")
						)
					}
			}
		}
	}
}
