import { DevLog } from "@Platon/const"

/**
 * @type {Map<Vue.Component, Array<{event: String, handler: function}>>}
 * @private
 */
const _events = new Map()

/**
 * @type {Map<string, function[]>}
 * @private
 */
const _handlers = new Map()

window._events = _events
window._handlers = _handlers

function $onEvent(component, event, handler) {
	DevLog("$onEvent", event, handler)

	if (typeof handler !== "function") return

	if (!_events.has(component)) {
		_events.set(component, [])
	}

	_events.get(component).push({
		event,
		handler
	})

	if (!_handlers.has(event)) {
		_handlers.set(event, [])
	}

	_handlers.get(event).push(handler)
}

async function $sendEvent(event, ...payload) {
	DevLog("$sendEvent", event, payload)

	if (_handlers.has(event)) {
		return Promise.allSettled(_handlers.get(event).map((fn) => fn(...payload)))
	} else {
		console.warn(`Sent event ${event}, but no handlers found`)
	}
}

function $clearEvents(component) {
	if (_events.has(component)) {
		for (let event of _events.get(component)) {
			if (_handlers.has(event.event)) {
				_handlers.set(
					event.event,
					_handlers.get(event.event).filter((fn) => fn !== event.handler)
				)
			}
		}

		_events.delete(component)
	}
}

window.$onEvent = $onEvent
window.$sendEvent = $sendEvent
window.$clearEvents = $clearEvents

export default {
	methods: {
		/**
		 * @param {string} event
		 * @param {function} handler
		 */
		$onEvent(event, handler) {
			$onEvent(this, event, handler)
		},

		/**
		 * @param {string} event
		 * @param {any} payload
		 */
		async $sendEvent(event, ...payload) {
			return $sendEvent(event, ...payload)
		},

		$clearEvents() {
			$clearEvents(this)
		}
	},

	beforeDestroy() {
		this.$clearEvents()
	}
}
