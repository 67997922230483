/**
 * @param {string} title
 * @param {string} text
 */
import { $language } from "@Platon/core/translations"
import { $rootToast } from "@Platon/core/core"

export function dangerToast(title, text) {
	$rootToast.toast(text, {
		title,
		appendToast: true,
		variant: "danger"
	})
}

/**
 * @param {string} title
 * @param {string} text
 */
export function successToast(title, text) {
	$rootToast.toast(text, {
		title,
		appendToast: true,
		variant: "success"
	})
}

/**
 * @param {string} title
 * @param {string} text
 */
export function infoToast(title, text) {
	$rootToast.toast(text, {
		title,
		appendToast: true,
		variant: "info"
	})
}

/**
 * @param {string} text
 */
export function errorToast(text) {
	dangerToast($language("platon.toast_error_title", "Хатолик"), text)
}

/**
 * @param {string} text
 */
export function greenToast(text) {
	successToast($language("platon.toast_success_title", "Хабар"), text)
}
