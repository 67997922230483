/**
 * @param {Object.<string, any>} args
 * @param {string} fn
 */
import { IS_DEV } from "@Platon/const"

let __fnMap = new Map()

/**
 * @param {string} fn
 * @return {Function}
 */
export function wrapFnWithContext(fn) {
	if (__fnMap.has(fn)) {
		return __fnMap.get(fn)
	} else {
		let formula = new Function(`return (${fn})`)
		__fnMap.set(fn, formula)

		return formula
	}
}

/**
 * @param {string} fn
 * @return {Function|any}
 */
export function wrapFnWithParams(fn) {
	if (__fnMap.has(fn)) {
		return __fnMap.get(fn)
	} else {
		let parametrized = fn.trim().replace(/@/g, "this.") || "1"
		if (IS_DEV) console.log("Generated fn:", parametrized)
		let formula = new Function(`return (${parametrized})`)
		__fnMap.set(fn, formula)

		return formula
	}
}

/**
 * @param {string} fn
 * @param {string[]} args
 *
 * @return {Function}
 */
export function wrapWithParamsAndArgs(fn, args) {
	let fnArgs = args.join(",")
	let fnKey = fnArgs + fn

	if (__fnMap.has(fnKey)) {
		return __fnMap.get(fnKey)
	} else {
		let parametrized = fn.replace(/@/g, "this.")
		if (IS_DEV) console.log("Generated fn:", parametrized)
		let formula = new Function(fnArgs, `return ${parametrized}`)
		__fnMap.set(fnKey, formula)

		return formula
	}
}

/**
 * @param {string} fn
 * @param {string[]} args
 * @return {Function}
 */
export function scopeFn(fn, args) {
	if (__fnMap.has(fn)) {
		return __fnMap.get(fn)
	} else {
		try {
			let formula = new Function(args.join(","), fn)

			__fnMap.set(fn, formula)

			return formula
		} catch (e) {
			return null
		}
	}
}

/**
 * @param {string} fn
 * @param {Object} ctx
 * @param {Object} args
 */
export function runScopeFn(fn, ctx = {}, args = {}) {
	let argNames = Object.keys(args)
	let argValues = Object.values(args)

	let f = scopeFn(fn, argNames)

	return f?.call(ctx, ...argValues)
}
