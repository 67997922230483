export default {
	methods: {
		/**
		 * @param {File} file
		 * @param {Cropper.Options} cropperOptions
		 * @return {Promise<File>}
		 */
		async cropImage(file, cropperOptions = {}) {
			return new Promise((resolve, reject) => {
				const component = () => import("@Platon/components/misc/ImageCropper")

				this.$modal.show(
					component,
					{
						file,
						cropperOptions,
						onCrop: (file) => {
							resolve(file)
						}
					},
					{
						width: window.innerWidth * 0.9
					},
					{
						closed: () => {
							reject()
						}
					}
				)
			})
		}
	}
}
