<template>
    <date-picker
        :range="range"
        :lang="language"
        :type="type"
        :inline="inline"
        :disabled="disabled"
        :title-format="titleFormat"
        :time-title-format="timeTitleFormat"
        :scroll-duration="scrollDuration"
        :default-value="defaultValue"
        :value="widgetModel"
        @input="emitPickerChanges"
    />
</template>

<script>
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"

export default {
    name: "DateRangePicker",
    /** Overridden Picker Component Properties */
    props: {
        // defaultValue - as its name, that prop can be widget's default v-model (value)
        defaultValue: {
            type: [Array, Date],
            default: null
        },
        // <type> - properties, date|datetime|year|month|time|week
        type: {
            type: String,
            default: "date"
        },
        range: {
            default: Boolean,
            default: false
        },
        language: {
            type: Object,
            default: null
        },
        inline: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        titleFormat: {
            type: String,
            default: "YYYY-MM-DD"
        },
        timeTitleFormat: {
            type: String,
            default: "YYYY-MM-DD"
        },
        scrollDuration: {
            type: Number,
            default: 100
        }
    },
    data() {
        return {
            widgetModel: this.defaultValue
        }
    },
    components: { DatePicker },
    methods: {
        emitPickerChanges(value) {
            this.widgetModel = value
            this.$emit("input", value)
        }
    }
}
</script>
