import { runScopeFn, wrapWithParamsAndArgs } from "@Platon/core/condition"
import { PermissionsScope } from "@Platon/core/runScope"

class ParametrizedString {
	/**
	 * @param {string} text
	 * @param {object} context
	 */
	replace(text, context = {}) {
		if (typeof text === "string" && text.startsWith("vjs:")) {
			try {
				// cut "vjs:" off
				const js = text.substring(4)

				return runScopeFn(js, context, PermissionsScope())
			} catch (e) {
				console.warn(e)
				return text
			}
		} else if (text != null && (text.includes("@") || text.startsWith("fjs:"))) {
			let js = text.trim()
			let fullJs = js.startsWith("fjs:")

			if (fullJs) {
				js = js.substring(4)
			}

			let fn = fullJs
				? js // for js
				: "`" + js.replace(/@([\w\d]+)/g, "${this.$1}") + "`" // for text

			let scope = PermissionsScope()

			try {
				return wrapWithParamsAndArgs(fn, Object.keys(scope)).apply(context, Object.values(scope))
			} catch (e) {
				console.warn(e)
				return text
			}
		} else return text
	}
}

export default new ParametrizedString()
