import PlatonPlugin from "@Platon/core/plugins/PlatonPlugin"
import PluginEvents from "@Platon/core/plugins/PluginEvents"
import { API_BASE_URL } from "@Platon/const"

export default class LoadUserOrganizationsAfterLogin extends PlatonPlugin {
	load(core) {
		super.load(core)

		core.registerEvent(PluginEvents.AfterLogin, this.loadUserOrganizations.bind(this))
		core.registerEvent(PluginEvents.AfterAuth, this.loadOrgsFromLocalStorage.bind(this))
	}

	/**
	 * @param {PlatonPluginCore} core
	 * @param {User} user
	 * @param {string} token
	 */
	async loadUserOrganizations(core, user, token) {
		if (!user.registeredWithEimzo) {
			return
		}

		// Legal entities doesn't have organizations
		if (user.eimzoAllowToLogin === "legal") {
			return
		}

		let organizations = await this.core.$root.$api.get("/auth/user_organizations", {
			baseURL: API_BASE_URL,
			headers: {
				Authorization: `Bearer ${token}`
			}
		})

		/**
		 * @type {UserOrganization[]}
		 */
		let organizationList = organizations.data

		if (user.eimzoAllowToLogin && ["all", "individuals"].includes(user.eimzoAllowToLogin) && user.pinfl) {
			organizationList.push({
				pinfl: user.pinfl.toString(),
				name: user.fullName
			})
		}

		localStorage.setItem("user_organizations", JSON.stringify(organizationList))

		this.loadIntoState(organizationList)

		// If user has only contains 1 organization and user organization is not set, set this org to user org
		if (organizationList.length === 1 && !user.orgId) {
			let orgId = organizationList[0].pinfl || organizationList[0].tin
			await this.core.store.dispatch("platon/switchOrganization", { orgId })
		}
	}

	/**
	 * @param {UserOrganization[]} organizations
	 */
	loadIntoState(organizations) {
		this.core.store.commit("platon/setUserParam", { key: "organizations", value: organizations })
	}

	async loadOrgsFromLocalStorage() {
		let user = this.core.store.state.platon.user

		if (user) {
			// Legal entities doesn't have organizations
			if (user.eimzoAllowToLogin === "legal") {
				return
			}

			let orgs = JSON.parse(localStorage.getItem("user_organizations"))

			this.loadIntoState(Array.isArray(orgs) ? orgs : [])
		}
	}
}
