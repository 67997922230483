/**
 * @param {DynamicComponent} c
 * @param {any} extraComponents
 * @returns {Partial<ComponentOptions>}
 */
export function transformJsToComponent(c, extraComponents = undefined) {
	let component = {
		template: c.content,
		name: c.name,
		components: extraComponents
	}

	try {
		if (c.js) {
			const buildComponent = new Function(`return (${c.js})`)()

			component = Object.assign(component, buildComponent)
		}
	} catch (e) {
		console.warn(`Failed to build component ${c.name}`, e)
		console.warn(`JS`, c.js)
	}

	return component
}
