<template>
    <b-tab no-body :class="classes" :style="styles">
        <template #title>
            <PlatonLink
                v-if="isAdmin && isMyProject(tab)"
                class="small over-target ml-2"
                tabindex="-1"
                :link="`/forms/form_elements/${tab.id}?_target=modal&form_name=${formMeta.name}`"
            >
                <i class="fa fa-cog"></i>
            </PlatonLink>

            <span v-html="tab.label"></span>
        </template>

        <div class="form-row">
            <PlatonFormElement element-type="form_elements" :item="e" :key="e.id" v-for="e in tab.children" />
        </div>
    </b-tab>
</template>

<script>
import PermissionMixin from "@Platon/mixins/table/PermissionMixin"
import FormDataMixin from "@Platon/components/form/FormDataMixin"

export default {
    name: "TabControl",

    mixins: [PermissionMixin, FormDataMixin],

    props: {
        /**
         * @type PlatonTab
         */
        tab: {
            required: true
        }
    },
    computed: {
        styles() {
            return this.tab.cssStyle || ""
        },
        classes() {
            let cls = ["p-3"]
            if (this.tab.cssClasses) cls.push(this.tab.cssClasses)
            return cls
        }
    }
}
</script>
