import ValueEmitter from "@Platon/mixins/ValueEmitter"

export default {
	mixins: [ValueEmitter],

	props: {
		/** @type PlatonFormElement */
		item: {},
		name: {},
		disabled: {},
		readonly: {},
		placeholder: {},
		id: {}
	},

	data() {
		let attrs = { ...this.$attrs } || {}

		if (this.item && this.item.attributes) {
			attrs = { ...attrs, ...this.item.attributes }
		}

		return {
			attrs,
			flashErrors: []
		}
	},

	computed: {
		combinedErrors() {
			let errors = [...this.flashErrors]

			if (this.validationErrors) {
				errors.push(...this.validationErrors)
			}

			return errors
		},

		isDisabledOrReadOnly() {
			return this.disabled || this.readonly
		}
	},

	methods: {
		focusField() {
			let input = this.$el.querySelector("input")

			if (input) {
				input.focus({
					preventScroll: true
				})
			}
		}
	}
}
