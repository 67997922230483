<template>
    <div :class="`ProfileViewContainer ${className}`">
        <slot />
    </div>
</template>

<script>
export default {
    name: "ProfileContainer",
    props: {
        className: {
            type: String,
            default: ""
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./utils/mixins.scss";
.ProfileViewContainer {
    padding: 20px;
    background-color: #fff;
}
</style>
