import LoadingView from "@Platon/components/extended/LoadingView"

export default {
	methods: {
		/**
		 * @param {HTMLTableElement} table
		 */
		exportTableXlsx(table) {
			this.$modal.show(
				() => ({
					component: import("@Platon/components/misc/ExportTableDialog"),
					loading: LoadingView,
					delay: 200
				}),
				{ table }
			)
		}
	}
}
