<template>
    <div class="p-1 px-2">
        <div class="float-left mt-1 pr-2">
            <div class="icon" ref="file" :style="{ 'background-color': fileIconColor }">
                {{ fileExtension }}
            </div>
        </div>

        <div class="pl-2">
            <div class="file-name d-flex">
                <div class="d-inline-block text-truncate overflow-hidden flex-grow-1">{{ fileName }}</div>
                <div
                    class="ml-1 cursor-pointer"
                    v-if="!isUploading && !isUploadError && canDownload"
                    @click="openFilePreviewModal(remoteFile)"
                >
                    <i class="fas fa-eye"></i>
                </div>
                <div
                    class="ml-1 cursor-pointer"
                    v-if="!isUploading && !isUploadError && canDownload"
                    @click="downloadRemoteFile"
                >
                    <i class="fa fa-download"></i>
                </div>
                <div
                    class="ml-1 cursor-pointer"
                    v-if="!isUploading && !isUploadError && !uploadControl.isDisabledOrReadOnly"
                    @click="deleteUploadedFile"
                >
                    <i class="fa fa-trash text-danger"></i>
                </div>
                <div class="ml-1 cursor-pointer" v-if="isUploadError" @click="uploadFile()">
                    <i class="fa fa-redo"></i>
                </div>
                <div class="ml-1 cursor-pointer d-flex align-items-center" v-if="isUploading">
                    <b-spinner variant="primary" style="width: 10px; height: 10px; border-width: 0.12em" />
                </div>
                <div class="ml-1 cursor-pointer" v-if="isUploading || isUploadError" @click="cancelUpload">
                    <i class="fa fa-times text-danger"></i>
                </div>
            </div>
            <div class="uploaded-date">{{ fileDescription }}</div>
        </div>
    </div>
</template>

<script>
import UploadMixin from "@Platon/components/form/controls/UploadMixin"
import FilePreviewMixin from "@Platon/components/misc/preview/FilePreviewMixin"

/**
 * @property {PlatonFormElement} uploadControl
 */
export default {
    name: "UploadMultipleItem",

    mixins: [UploadMixin, FilePreviewMixin],

    props: {
        info: {}
    },

    inject: ["item", "uploadControl"],

    mounted() {
        if (this.info && this.info.file) {
            this.onFileSelected(this.info.file)
        }
    }
}
</script>

<style scoped>
.file-name,
.uploaded-date {
    font-size: 13px;
}

.file-name i {
    font-size: 12px;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    height: 32px;
    width: 28px;
    font-size: 12px;
    border-radius: 0 8px 0 0;
}
</style>
