import PlatonPlugin from "@Platon/core/plugins/PlatonPlugin"
import PluginEvents from "@Platon/core/plugins/PluginEvents"
import { pickUserOrganization } from "@Platon/mixins/pickUserOrganization"

export default class PickOrganizationAfterAuth extends PlatonPlugin {
	load(core) {
		super.load(core)

		core.registerEvent(PluginEvents.AfterAuth, this.afterAuth.bind(this))
	}

	/**
	 * @param {PlatonPluginCore} core
	 * @param {User} user
	 */
	async afterAuth(core, user) {
		if (!user.registeredWithEimzo) {
			return
		}

		// jismoniy bo'lsa, legal bo'lsa korxonalari bor bo'lsa kiradi
		// jismoniy bo'lsa, individuals bo'lsa faqat o'zi bilan kiradi
		// jismoniy bo'lsa, all bo'lsa biror yo o'zini yoki korxonani tanlab kiradi

		// yuridik bo'lsa, legal bo'lsa biror korxonani tanlab kiradi, hozircha faqat o'zi bilan kiradi
		// yuridik bo'lsa, individuals bo'lsa kirolmaydi umuman
		// yuridik bo'lsa, all bo'lsa biror korxonani tanlab kiradi
		// yuridiklar faqat hozir bitta o'zini korxonasi bilan kiradi

		// agar user jismoniy bo'lsa
		if (user.pinfl) {
			// agar tizimga faqat jismoniylar kirishi ruxsat berilgan bo'lsa
			if (["all", "legal"].includes(user.eimzoAllowToLogin)) {
				// agar user tanlanmagan bo'lsa
				if (!user.orgId && Array.isArray(user.organizations) && user.organizations.length > 0) {
					let userOrgId = await pickUserOrganization(core.$root, user.organizations)
					core.store.commit("platon/setUserParam", { key: "orgId", value: userOrgId })
				}
			}
		}
	}
}
