import { actions } from "@Platon/store/platon/actions"
import { getters } from "@Platon/store/platon/getters"
import { mutations } from "@Platon/store/platon/mutations"
import state from "@Platon/store/platon/state"

export const PlatonModule = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
}
