import qs from "qs"

/***
 * @param {string} url
 * @param {object} params
 */

export default function encodedUrlBuilder(url, params) {
	const encodedParams = qs.stringify({ ...params }, { arrayFormat: "indices" })

	return `${url}?${encodedParams}`
}
