<template>
    <BaseControl
        :has-append="$attrs.hasControls"
        :has-prepend="$attrs.hasControls"
        v-bind="$attrs"
        class="position-relative"
        :errors="combinedErrors"
    >
        <p-datepicker
            ref="input"
            :placeholder="_placeholder"
            v-model="local"
            :class="classes"
            class="date-picker-input"
            :config="options"
            :readonly="isReadOnly"
            :disabled="isDisabled"
        >
        </p-datepicker>

        <div class="icon position-absolute" @click="focusField()">
            <i class="fa fa-calendar-alt" />
        </div>

        <template v-if="$attrs.hasControls" #append>
            <b-button @click="addDays(1)" variant="light-outline"><i class="fa fa-chevron-right" /></b-button>
        </template>

        <template v-if="$attrs.hasControls" #prepend>
            <b-button @click="addDays(-1)" variant="light-outline"><i class="fa fa-chevron-left" /></b-button>
        </template>
    </BaseControl>
</template>

<script>
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import ValidationMixin from "@Platon/mixins/ValidationMixin"
import "@Platon/external/datepicker/lang-la.js"
import { BACKEND_TIMEZONE, DEFAULT_DATETIME_FORMAT, DEFAULT_SERVER_DATETIME_FORMAT } from "@Platon/const"
import { localeInfo } from "@Platon/core/translations"
import DatepickerMixin from "@Platon/components/form/controls/DatepickerMixin"
import InputControl2Mixin from "@Platon/mixins/InputControl2Mixin"

import moment from "moment-timezone"

export default {
    name: "DatetimeControl",

    mixins: [InputControl2Mixin, ValidationMixin, DatepickerMixin],

    props: {
        value: {}
    },

    components: {
        BaseControl
    },

    data() {
        /** @type moment.Moment */
        let local = moment.tz(this.value || this.item.defaultValue, DEFAULT_SERVER_DATETIME_FORMAT, BACKEND_TIMEZONE)
        let dateFormat = this.item.format || DEFAULT_DATETIME_FORMAT

        return {
            dateFormat: dateFormat,
            local: local.isValid() ? local.local().format(dateFormat) : null
        }
    },

    watch: {
        local(val) {
            let emitValue = val
                ? moment(val, this.dateFormat).tz(BACKEND_TIMEZONE).format(DEFAULT_SERVER_DATETIME_FORMAT)
                : null

            if (emitValue !== this.value) this.$emit("input", emitValue)
        },

        value(val) {
            this.local = val
                ? moment.tz(val, DEFAULT_SERVER_DATETIME_FORMAT, BACKEND_TIMEZONE).local().format(this.dateFormat)
                : null
        }
    },

    computed: {
        options() {
            let extra = {}

            if (this.item.minDateTime) {
                extra.minDate = moment.tz(this.item.minDateTime, DEFAULT_SERVER_DATETIME_FORMAT, BACKEND_TIMEZONE)
            }

            if (this.item.maxDateTime) {
                extra.maxDate = moment.tz(this.item.maxDateTime, DEFAULT_SERVER_DATETIME_FORMAT, BACKEND_TIMEZONE)
            }

            return {
                format: this.dateFormat,
                locale: localeInfo.locale,
                useCurrent: false,
                showClear: true,
                showClose: true,
                ...extra
            }
        },

        classes() {
            return [`text-${this.item.textAlign || "left"}`]
        },

        _placeholder() {
            return this.$props.placeholder || this.item.placeholder || this.dateFormat
        }
    }
}
</script>

<style scoped>
.icon {
    right: 1rem;
    bottom: 0.5rem;
}
</style>
