/**
 *
 * @param {*} configuration { api: String, onSuccess: Callback, onError: Callback}
 * @example
 *      async function getExportedDocuments() {
 *          await asyncHandler(API, {
 *              onSuccess: () => { },
 *              onError: () => { }
 *          });
 *      }
 *
 * @description Async Handler - will keep your code clean, and makes you to concentrate what actually you wanted to do
 */
export async function asyncHandler(to, configuration) {
	const { onSuccess, onError, type = "get", details = null } = configuration
	try {
		let handlerResponse = null
		if (type === "get") handlerResponse = await window.$http.get(to)
		if (type === "post") handlerResponse = await window.$http.post(to)
		if (onSuccess) onSuccess(handlerResponse)
	} catch (error) {
		if (onError) onError(error)
		throw new Error(error)
	}
}

export function is(condition, argument) {
	if (condition) return argument
}

export function makeAnId() {
	return "xxxxx4xx".replace(/[xy]/g, function (x) {
		const r = (Math.random() * 16) | 0
		const v = x === "x" ? r : (r & 0x3) | 0x8

		return v.toString(16)
	})
}
