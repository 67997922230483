<template>
    <component
        :errors="combinedErrors"
        @input="updateLocal"
        :disabled="disabled"
        :readonly="readonly"
        :value="local"
        :item="item"
        v-bind="$attrs"
        :is="uploadComponent"
        ref="manager"
    />
</template>

<script>
import UploadSingle from "./UploadSingle.vue"
import UploadMultiple from "./UploadMultiple.vue"
import InputControlMixin from "@Platon/mixins/InputControlMixin"
import ValidationMixin from "@Platon/mixins/ValidationMixin"
import UploadUtilsMixin from "@Platon/components/form/controls/UploadUtilsMixin"

export default {
    name: "UploadControl",
    components: { UploadSingle, UploadMultiple },

    mixins: [InputControlMixin, ValidationMixin, UploadUtilsMixin],

    computed: {
        uploadComponent() {
            if (this.item.isMultiple) {
                return "upload-multiple"
            } else {
                return "upload-single"
            }
        }
    }
}
</script>

<style></style>
