export default {
	computed: {
		text() {
			if (typeof this.item.buttonText === "string" || this.item.buttonText.startsWith("platon.")) {
				return this.$l(this.item.buttonText, this.item.buttonText)
			} else {
				return this.item.buttonText
			}
		}
	}
}
