<template>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th v-for="cell in definition">
                    {{ cell.value }}
                </th>
                <th width="32"></th>
            </tr>
        </thead>
        <tr v-for="(row, index) in rows" :key="`row-${index}`" class="position-relative">
            <td v-for="cell in definition" :key="cell.key">
                <input
                    @input="() => onInput(index)"
                    class="form-control form-control-sm"
                    type="text"
                    v-model="rows[index][cell.key]"
                    :placeholder="cell.value"
                />
            </td>

            <td @click="() => rows.splice(index, 1)" class="remove">
                <i class="fa fa-times"></i>
            </td>
        </tr>
    </table>
</template>

<script>
export default {
    name: "MultiKeyValueControl",

    props: {
        /** @type KeyValue[] */
        definition: {
            type: Array,
            default: () => []
        },

        value: {
            type: Array,
            default: () => []
        }
    },

    data() {
        let rows = []
        rows.push(...this.value)
        rows.push({})

        return {
            rows: rows
        }
    },

    methods: {
        onInput(rowId) {
            if (rowId === this.rows.length - 1) {
                this.rows.push({})
            }
        }
    },

    watch: {
        rows: {
            /**
             * @param {Array} val
             */
            handler(val) {
                if (val.length === 0) {
                    val.push({})
                }

                if (val.length > 0) {
                    this.$emit("input", val.slice(0, val.length - 1))
                }
            },
            immediate: true,
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
table td {
    padding: 2px !important;
}

input {
    border-color: #ffffff00;
    transition: none;

    &:focus {
        border: inherit;
    }
}

.remove {
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
}
</style>
