/**
 * @typedef PlatonPluginRegisterOptions
 *
 * @property {number} priority lower priority will be loaded first
 * @property {PlatonPlugin} plugin
 */

export default class PlatonPlugin {
	/**
	 * Will be called when plugin loaded into platon
	 * @param {PlatonPluginCore} core
	 */
	load(core) {
		this.core = core
	}

	/**
	 * Will be called when plugin unloaded from platon
	 * @param {PlatonPluginCore} core
	 */
	async unload(core) {}
}
