<template>
    <div class="UnAuthorizationWithMFA">
        <AuthorizationWithMFA
            :state="{
                verified: state.verified,
                isError: state.isError
            }"
            @authorization="verifyAuthorizationViaMFA"
            @authorizationSubmit="submitUnAuthorizationDigits"
        />
        <div class="UnAuthorizationWithMFA__options">
            <ActionButton
                :isLoading="state.isAuthenticating"
                @onclick="submitUnAuthorizationDigits"
                :className="`option-action-button ${state.isError && 'error'} ${state.verified && 'succeed'}`"
            >
                {{ state.isError ? "Қайта урунинг" : "Ўчириш" }}
            </ActionButton>
        </div>
    </div>
</template>

<script>
import AuthorizationWithMFA from "./AuthorizationWithMFA"
import ActionButton from "../../components/actionButton"

export default {
    name: "UnAuthorizationWithMFA",
    components: { AuthorizationWithMFA, ActionButton },
    props: {
        state: {
            type: Object,
            default: () => {
                return {
                    isError: false,
                    verified: false,
                    isAuthenticating: false,
                    digits: null
                }
            }
        }
    },
    methods: {
        verifyAuthorizationViaMFA(digits) {
            this.$emit("getUnAuthDigits", digits)
        },
        submitUnAuthorizationDigits() {
            this.$emit("submitUnAuth")
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../components/utils/mixins.scss";

.UnAuthorizationWithMFA {
    height: 100%;

    & .AuthWithMFA {
        height: 345px;
        @include display-flex-center;

        & .AuthorizationWithMFA {
            border-color: var(--pl-btn-primary) !important;
        }
    }

    &__options {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 20px;

        & .option-action-button {
            &.error {
                background-color: $df-secondary;
                border-color: $df-secondary;
            }

            &.succeed {
                background-color: $df-success;
                border-color: $df-secondary;
            }
        }
    }
}
</style>
