export default {
	props: {
		/** @type PlatonFormElement */
		item: {}
	},

	data() {
		return {
			flashErrors: []
		}
	},

	computed: {
		combinedErrors() {
			let errors = [...this.flashErrors]

			if (this.validationErrors) {
				errors.push(...this.validationErrors)
			}

			return errors
		},

		isDisabledOrReadOnly() {
			return this.isDisabled || this.isReadOnly
		},

		isRequired() {
			return this.item.isRequired
		},

		isReadOnly() {
			return this.item.isReadOnly
		},

		isDisabled() {
			return this.item.isDisabled
		}
	},

	methods: {
		focusField() {
			let input = this.$el.querySelector("input")

			if (input) {
				input.focus({
					preventScroll: true
				})
			}
		}
	}
}
