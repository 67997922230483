import { mapState } from "vuex"
import { themeModes } from "@Platon/const"

export default {
	computed: {
		...mapState({
			theme: (state) => state.platon.theme,
			themeColor: (state) => state.platon.themeColor
		}),
		activeTheme() {
			return themeModes.find((mode) => mode.key === this.theme) || themeModes[0]
		}
	},

	watch: {
		theme: {
			immediate: true,
			handler() {
				document.documentElement.setAttribute("theme", this.activeTheme.key)
			}
		},
		themeColor: {
			immediate: true,
			handler(newThemeColor) {
				const matchedColor =
					this.activeTheme.colors.find((color) => color.key === newThemeColor) || this.activeTheme.colors[0]
				document.documentElement.setAttribute("color", matchedColor.key)
			}
		}
	}
}
