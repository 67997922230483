import Vue from "vue"
import "moment"
import { API_BASE_URL, DEFAULT_LANG, DEFAULT_SERVER_DATE_FORMAT, DevLog, LANG_DEFINITION } from "@Platon/const"
import qs from "qs"

let translations = Vue.observable({})

function getPlatonLocale() {
	let forceLang = localStorage.getItem("platon_locale")

	let url = qs.parse(window.location.search.substr(1))

	if (url && url.locale) {
		let found = LANG_DEFINITION.find((l) => l.code === url.locale)

		if (found) {
			// Don't override current locale, if locale was sent by query params
			// localStorage.setItem('platon_locale', found.code)

			forceLang = found.code
		}
	}

	return forceLang || DEFAULT_LANG
}

export let localeInfo = Vue.observable({
	locale: getPlatonLocale()
})

/**
 * @param {string} key
 * @param {string} fallbackValue
 * @return {string}
 */
export function $language(key, fallbackValue) {
	let text = translations[key]

	if (text === null || text === undefined) {
		DevLog("Translation key not found", { key, fallbackValue })
	}

	return text || fallbackValue
}

/**
 * @param {string[]|number[]} args
 * @param {string} key
 * @param {string} fallback
 */
export function $parametrizedLanguage(key, args, fallback) {
	let result = $language(key, fallback)

	for (let i = 0; i < args.length; i++) {
		let reg = new RegExp(`\\{${i}\\}`, "g")

		result = result.replace(reg, args[i])
	}

	return result
}

/**
 * @param {object} translations
 */
export function saveTranslations(translations) {
	if (typeof translations === "object") {
		localStorage[`tr_${localeInfo.locale}`] = JSON.stringify(translations)

		let today = moment().format(DEFAULT_SERVER_DATE_FORMAT)
		localStorage.setItem("tr_last_fetched", today)

		loadTranslations()
	}
}

export function loadTranslations() {
	let langKey = `tr_${localeInfo.locale}`

	let today = moment().format(DEFAULT_SERVER_DATE_FORMAT)
	let syncDate = localStorage.getItem("tr_last_fetched")
	if (!syncDate || syncDate < today || !localStorage.getItem(langKey)) {
		fetchTranslations()
	} else {
		let values = JSON.parse(localStorage.getItem(langKey) || "{}")

		Object.entries(values).forEach(([key, value]) => {
			Vue.set(translations, key, value)
		})
	}
}

export async function fetchTranslations() {
	let response = await window.$http.get("web/assets/translations", {
		baseURL: API_BASE_URL
	})
	saveTranslations(response.data)
}

/**
 * @param {string} newLocale
 */
export async function switchLocale(newLocale) {
	setNewLocale(newLocale)

	await fetchTranslations()

	let url = new URL(location.href)
	url.searchParams.delete("locale")

	location.href = url.toString()
}

/**
 * @param {string} newLocale
 */
export function setNewLocale(newLocale) {
	if (isValidLocale(newLocale)) {
		localeInfo.locale = newLocale
		localStorage.setItem("platon_locale", newLocale)
	}
}

export function isValidLocale(localeCode) {
	return LANG_DEFINITION.findIndex((l) => l.code === localeCode) >= 0
}
