<template>
    <Modal v-bind="options.modalProps" :footer-actions="actions">
        <template #title>
            <span style="font-size: 18px" v-html="title" />
        </template>

        <component v-if="component" :is="component" v-bind="componentProps" ref="component" />
        <div v-else style="font-size: 16px" v-html="text" />
    </Modal>
</template>

<script>
import Modal from "@Platon/components/extended/Modal.vue"
import ModalCloseOnEscMixin from "@Platon/mixins/ModalCloseOnEscMixin"

export default {
    name: "AlertDialog",
    components: { Modal },

    mixins: [ModalCloseOnEscMixin],

    props: {
        title: {},
        text: {},

        component: {
            default: null
        },

        componentProps: {
            default: null
        },

        options: {
            default: () => {}
        },

        callback: {}
    },

    data() {
        return {
            confirmDisabled: false,
            rejectDisabled: false
        }
    },

    methods: {
        sendEvent(evt) {
            this.callback && this.callback(evt, this)

            this.$emit("close")
        }
    },

    computed: {
        /**
         * @return {ModalAction[]}
         */
        actions() {
            return [
                {
                    text: this.options.confirmText || this.$l("platon.yes", "Ҳа"),
                    icon: this.options.confirmIcon,
                    disabled: this.confirmDisabled,
                    handler: () => {
                        this.sendEvent("confirmed")
                    }
                },
                {
                    text: this.options.rejectText || this.$l("platon.no", "Йўқ"),
                    icon: this.options.rejectIcon,
                    disabled: this.rejectDisabled,
                    handler: () => {
                        this.sendEvent("rejected")
                    }
                }
            ]
        }
    }
}
</script>

<style></style>
