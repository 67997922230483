export default {
	methods: {
		/**
		 * @param {ContextMenuItem[]} options
		 * @param {MouseEvent} event
		 */
		createContextMenu(options, event) {
			this.createContextMenuWithTitle(null, options, event)
		},

		/**
		 * @param {?string} title
		 * @param {ContextMenuItem[]} options
		 * @param {MouseEvent} event
		 */
		createContextMenuWithTitle(title, options, event) {
			this.$platonApp.__context_menu.show(title, options, {
				x: event.pageX,
				y: event.pageY
			})
		}
	}
}
